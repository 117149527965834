const TECHNOLOGIES = [
  {
    id: 'html5',
    alt: 'HTML5',
    src: '/assets/logos/html5-logo-min.png',
  },
  {
    id: 'css',
    alt: 'CSS3',
    src: '/assets/logos/css-logo-min.png',
  },
  {
    id: 'js',
    alt: 'JavaScript',
    src: '/assets/logos/js-logo-min.png',
  },
  {
    id: 'react',
    alt: 'React',
    src: '/assets/logos/react-logo-min.png',
  },
  {
    id: 'react-router',
    alt: 'React Router',
    src: '/assets/logos/react-router-logo.png',
  },
  {
    id: 'redux',
    alt: 'Redux',
    src: '/assets/logos/redux-logo-min.png',
  },
  {
    id: 'sass',
    alt: 'Sass',
    src: '/assets/logos/sass-logo-min.png',
  },
  {
    id: 'eslint',
    alt: 'Eslint',
    src: '/assets/logos/eslint-logo-min.png',
  },
  {
    id: 'prettier',
    alt: 'Prettier',
    src: '/assets/logos/prettier-logo-min.png',
  },
  {
    id: 'babel',
    alt: 'Babel',
    src: '/assets/logos/babel-b-logo-min.png',
  },
  {
    id: 'jest',
    alt: 'Jest',
    src: '/assets/logos/jest-logo-min.png',
  },
  {
    id: 'webpack',
    alt: 'Webpack',
    src: '/assets/logos/webpack-logo-min.png',
  },
  {
    id: 'nodejs',
    alt: 'Node.js',
    src: '/assets/logos/nodejs-logo-min.png',
  },
  {
    id: 'npm',
    alt: 'NPM',
    src: '/assets/logos/npm-logo-min.png',
  },
  {
    id: 'git',
    alt: 'Git',
    src: '/assets/logos/git-logo-min.png',
  },
  {
    id: 'mysql',
    alt: 'Mysql',
    src: '/assets/logos/mysql-logo-min.png',
  },
  {
    id: 'nginx',
    alt: 'NGINX',
    src: '/assets/logos/nginx-logo-min.png',
  },
];

export default TECHNOLOGIES;
