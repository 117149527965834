const ROUTES = [
  {
    id: 'FEATURE_BY',
    url: '/',
    menuLabel: 'Home',
    title: 'FEATURE.BY',
    pageTitle: 'Feature - home',
  },
];

export default ROUTES;
