import { URLS } from '../../../../../../constants';
import goToPageWithAuthParams from '../../../../../../utils/go-to-page-with-auth-params';

const { FIFTEEN_PUZZLE_HOST, GAME_2048_HOST } = URLS;

const COMMON_ITEMS = [
  {
    id: 'fifteen-puzzle',
    label: 'Fifteen puzzle',
    url: FIFTEEN_PUZZLE_HOST,
  },
  {
    id: 'game-2048',
    label: '2048',
    url: GAME_2048_HOST,
  },
];

const getFeatureByMenuOptions = ({ SPECIAL_ITEMS = [] }) =>
  COMMON_ITEMS.concat(SPECIAL_ITEMS).map(item => ({
    onClick: goToPageWithAuthParams(item.url),
    ...item,
  }));

export default getFeatureByMenuOptions;
