import { connect } from 'react-redux';

import MainMenuForm from './MainMenuForm';
import {
  acCloseAllModals,
  acAddModal,
  acRemoveLastModal,
  acGetSpecialRoutes,
} from '../../../../../store/actions';
import { getIsLogged } from '../../../../../store/selectors';

const mapStateToProps = state => ({
  isLogged: getIsLogged(state),
});

const mapDispatchToProps = {
  closeAllModals: acCloseAllModals,
  closeModal: acRemoveLastModal,
  getSpecialRoutes: acGetSpecialRoutes,
  openModal: acAddModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenuForm);
