import { get as getValue } from 'lodash';

export const get = ({ url, headers }) =>
  fetch(url, {
    method: 'GET',
    headers,
  });

export const post = ({ url, headers, body }) =>
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(headers && headers),
    },
    body: JSON.stringify(body),
  });

export const put = ({ url, headers, body }) =>
  fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...(headers && headers),
    },
    body: JSON.stringify(body),
  });

export const parseResponse = response => {
  const contentType = response.headers.get('content-type');

  if (contentType.includes('json')) return response.json();
  if (contentType.includes('text')) return response.text();
  return Promise.resolve('');
};

export const getNetworkError = data => ({
  message: getValue(data, 'message', 'Network error'),
  status: getValue(data, 'status', 500),
  date: new Date().toLocaleString(),
});
