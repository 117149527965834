import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Header from './shared/Header';
import Content from './shared/Content';
import eventEmitter from '../App/shared/event-emitter';
import { COMMON, EVENTS } from '../../constants';

import styles from './Page.module.scss';
import setPageTitle from '../../utils/set-page-title';

const Page = ({ id, pageTitle, title, acSetLocation }) => {
  const location = useLocation();
  const content = useRef(null);

  const handleResize = () => {
    const screenHeight = document.body.offsetHeight;
    const contentTop = content.current.getBoundingClientRect().top;
    const newContentHeight = screenHeight - contentTop - COMMON.REM;
    content.current.style.height = `${newContentHeight}px`;
  };

  useEffect(() => {
    setPageTitle(pageTitle);
    acSetLocation(location);
  }, [acSetLocation, location, pageTitle]);

  useEffect(() => {
    eventEmitter.on(EVENTS.RESIZE, handleResize);
  }, []);

  return (
    <div className={styles['page']}>
      <Header title={title} />
      <Content pageId={id} childRef={content} />
    </div>
  );
};

Page.propTypes = {
  id: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  acSetLocation: PropTypes.func.isRequired,
};

export default Page;
