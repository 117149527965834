import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import List from '../../../../../../List';
import ListItem from '../../../../../../List/shared/ListItem';
import { TECHNOLOGIES } from '../../../../../../../constants';

import styles from './FeatureBy.module.scss';

const FeatureBy = ({ openMainMenu, openAccountMenu }) => {
  const techItems = useMemo(
    () =>
      TECHNOLOGIES.map(({ id, alt, src }) => {
        return (
          <ListItem key={id} className={styles['list-item']}>
            <img src={src} alt={alt} />
          </ListItem>
        );
      }),
    [],
  );

  return (
    <div className={styles['feature-by']}>
      <section className={styles['main-section']}>
        <h2 className={styles['main-section__title']}>About Feature.by</h2>
        <p className={styles['main-section__paragraph']}>
          Feature.by is a&nbsp;place where I develop my&nbsp;web applications
          just for&nbsp;fun. I write code and the&nbsp;same time I&nbsp;play the&nbsp;games.
          It&nbsp;would be&nbsp;pleasure for&nbsp;me
          if&nbsp;someone else played these games&nbsp;too&nbsp;:)
        </p>
        <p className={styles['main-section__paragraph']}>
          Currently two well-known games are available
          in&nbsp;the&nbsp;main&nbsp;menu:
        </p>
        <List
          className={classNames(
            styles['main-section__paragraph'],
            styles['main-section__list'],
          )}
        >
          <ListItem
            className={classNames(styles.featured, styles['text-button'])}
            tabIndex={0}
            onClick={openMainMenu}
          >
            Fifteen puzzle
          </ListItem>
          <ListItem
            className={classNames(styles.featured, styles['text-button'])}
            tabIndex={0}
            onClick={openMainMenu}
          >
            2048
          </ListItem>
        </List>
        <p className={styles['main-section__paragraph']}>
          Simple free registration is available in the {' '}
          <span
            className={classNames(styles['text-button'], styles.featured)}
            role="button"
            tabIndex={0}
            onClick={openAccountMenu}
          >
            Account&nbsp;menu
          </span>{'. '}
          Just click&nbsp;on the&nbsp;user’s&nbsp;icon in the&nbsp;page&nbsp;header.
        </p>
        <p className={styles['main-section__paragraph']}>
          Registered users have additional options such&nbsp;as
          quick&nbsp;save/quick&nbsp;load, table
          of&nbsp;the&nbsp;best&nbsp;results and&nbsp;etc.
        </p>
        <p className={styles['main-section__paragraph']}>
          Just relax and have&nbsp;fun&nbsp;:)
        </p>
      </section>
      <section className={styles['secondary-section']}>
        <List className={styles['list']}>{techItems}</List>
      </section>
    </div>
  );
};

FeatureBy.propTypes = {
  openMainMenu: PropTypes.func.isRequired,
  openAccountMenu: PropTypes.func.isRequired,
};

export default FeatureBy;
