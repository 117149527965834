import { parseResponse, post, put } from '../utils/network-requests';
import { URLS } from '../constants';

const createCaptcha = async payload => {
  const url = URLS.CAPTCHA;

  const response = await post({
    url,
    body: payload,
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const updateCaptcha = async payload => {
  const url = URLS.CAPTCHA;

  const response = await put({
    url,
    body: payload,
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

export default {
  createCaptcha,
  updateCaptcha,
};
