import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './MenuItemLink.module.scss';

const MenuItemLink = ({ className, children, href, onClick }) => {
  const ownClassName = classNames(
    styles['menu-item-link'],
    className && className,
  );

  return (
    <a className={ownClassName} href={href} onClick={onClick}>
      {children}
    </a>
  );
};

MenuItemLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

MenuItemLink.defaultProps = {
  children: [],
  className: '',
  onClick: () => {},
};

export default MenuItemLink;
