import { useEffect } from 'react';
import PropTypes from 'prop-types';

import getMediaValue from '../../utils/get-media-value';

/**
 * Service component. Listens change of `matchmedia` and dispatches values to store
 * @param {string[]} mediaQueries
 * @param {function} acSetMedia
 * @returns {null}
 */
const MatchMediaService = ({ mediaQueries, acSetMedia }) => {
  const mqObjects = mediaQueries.map(query => window.matchMedia(query));

  useEffect(() => {
    mqObjects.forEach((obj, i) => {
      if ((i + 1) % 2 > 0) {
        obj.addEventListener('change', () => {
          acSetMedia(getMediaValue(mqObjects));
        });
      }
    });
    acSetMedia(getMediaValue(mqObjects));
  }, []);

  return null;
};

MatchMediaService.propTypes = {
  mediaQueries: PropTypes.arrayOf(PropTypes.string).isRequired,
  acSetMedia: PropTypes.func.isRequired,
};

export default MatchMediaService;
