import React from 'react';
import PropTypes from 'prop-types';

import contents from './shared/contents';

import styles from './Content.module.scss';

const Content = ({ pageId, childRef }) => {
  const defaultPageContent = () => 'Happy New Year and Merry Christmas!';
  const PageContent = contents[pageId] || defaultPageContent;

  return (
    <section className={styles['content']} ref={childRef}>
      <PageContent />
    </section>
  );
};

Content.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  childRef: PropTypes.object.isRequired,
  pageId: PropTypes.string.isRequired,
};

Content.defaultProps = {};

export default Content;
