import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { EVENTS } from '../../constants';

const {
  RESIZE,
  KEY_DOWN,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_UP,
  ESCAPE,
  REDO,
  UNDO,
} = EVENTS;

const Controller = ({ eventEmitter, setTouchScreenDetected }) => {
  const handleResize = e => {
    const { screen } = e.target;
    eventEmitter.emit(RESIZE, { screen });
  };

  const handleTouchScreenDetected = () => {
    document.removeEventListener('touchstart', handleTouchScreenDetected);
    setTouchScreenDetected(true);
  };

  const handleKeyDown = event => {
    event.stopPropagation();

    const { keyCode, ctrlKey } = event;

    if (event.isComposing || keyCode === 229) {
      return;
    }

    // const isModal = isFilledArray(store.getState().modals);

    switch (true) {
      case keyCode === 37:
        eventEmitter.emit(KEY_LEFT, { ctrlKey });
        break;

      case keyCode === 39:
        eventEmitter.emit(KEY_RIGHT, { ctrlKey });
        break;

      case keyCode === 38:
        eventEmitter.emit(KEY_UP, { ctrlKey });
        break;

      case keyCode === 40:
        eventEmitter.emit(KEY_DOWN, { ctrlKey });
        break;

      case keyCode === 90 && ctrlKey:
        eventEmitter.emit(UNDO);
        break;

      case keyCode === 89 && ctrlKey:
        eventEmitter.emit(REDO);
        break;

      case keyCode === 77 && ctrlKey:
        // key m
        // store.dispatch(acOpenModal(MODALS.MAIN_MENU));
        break;

      case keyCode === 27:
        eventEmitter.emit(ESCAPE);
        break;

      case keyCode === 17:
        // ctrl
        break;

      default:
      // console.log('document: key down: keyCode: ', keyCode);
    }
  };

  const startListen = () => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('keydown', handleKeyDown, false);
    document.addEventListener('touchstart', handleTouchScreenDetected);
  };

  useEffect(() => {
    startListen();
  }, []);

  return null;
};

Controller.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  eventEmitter: PropTypes.object.isRequired,
  setTouchScreenDetected: PropTypes.func.isRequired,
};

export default Controller;
