import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../Modal/Modal';
import PreloaderIcon from '../../icons/PreloaderIcon';

import styles from './Preloader.module.scss';

const Preloader = ({ isOpen }) => {
  return (
    <Modal
      className={styles['preloader']}
      id="Preloader"
      isOpen={isOpen}
      isCentered
    >
      <div className={styles['preloader-content']}>
        <div className={styles['preloader-content__spinner']}>
          <PreloaderIcon className={styles['spinner-icon']} />
        </div>
        <div className={styles['preloader-content__label']}>Is fetching...</div>
      </div>
    </Modal>
  );
};

Preloader.propTypes = {
  isOpen: PropTypes.bool,
};

Preloader.defaultProps = {
  isOpen: false,
};

export default Preloader;
