import { connect } from 'react-redux';

import FeatureBy from './FeatureBy';
import { acAddModal } from '../../../../../../../store/actions';
import { MODALS_ID } from '../../../../../../../constants';

const openMainMenu = () => acAddModal(MODALS_ID.MAIN_MENU);

const openAccountMenu = () => acAddModal(MODALS_ID.ACCOUNT_MODAL_MENU);

const mapDispatchToProps = {
  openMainMenu,
  openAccountMenu,
};

export default connect(null, mapDispatchToProps)(FeatureBy);
