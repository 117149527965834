import { combineReducers } from 'redux';

import auth from './auth-reducer';
import networking from './networking-reducer';
import media from './media-reducer';
import messages from './messages-reducer';
import modals from './modal-reducer';
import router from './router-reducer';

export default combineReducers({
  auth,
  networking,
  media,
  messages,
  modals,
  router,
});
