import { get } from 'lodash';

import { ROUTES } from '../constants';

const usePageId = location => {
  const pageUrl = get(location, 'pathname', '/');
  const route = ROUTES.find(({ url }) => url === pageUrl);

  return route.id;
};

export default usePageId;
