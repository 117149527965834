import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';

import reducer from './reducers';
import api from '../api';

const middlewares = [thunk.withExtraArgument(api)];

const storeConfigure = () =>
  createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));

export default storeConfigure;
