import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Form from '../../../../Form';
import FormButton from '../../../../Form/shared/FormButton';
import FormFieldset from '../../../../Form/shared/FormFieldset';
import FormInput from '../../../../Form/shared/controls/FormInput';
import FormToolbar from '../../../../Form/shared/FormToolbar';
import Legend from '../../../../Form/shared/Legend';
import Captcha from '../../../../Captcha';
import { MODES, CAPTCHA } from '../../../../../constants';

import styles from './AuthForm.module.scss';

const {
  field: { width: fieldWidth, height: fieldHeight },
  checkbox: { width: checkboxWidth, height: checkboxHeight },
} = CAPTCHA;

const { LOGIN, REGISTRATION, UPDATE } = MODES;

const getInitialState = currentLogin => ({
  login: currentLogin,
  email: '',
  password: '',
  confirmPassword: '',
  validation: {},
});

const AuthForm = ({
  currentLogin,
  isUpdateMode,
  closeAllModals,
  getUserProfile,
  onSubmit,
  onCancel,
  changePassword,
}) => {
  const [mode, setMode] = useState(isUpdateMode ? UPDATE : LOGIN);
  const [state, setState] = useState(getInitialState(currentLogin));
  const [captcha, setCaptcha] = useState({ code: '', isCompleted: false });

  useEffect(() => {
    if (mode === UPDATE) {
      getUserProfile(data => {
        setState({ ...getInitialState(currentLogin), ...data });
      });
    }
  }, [currentLogin, getUserProfile, mode]);

  const FIELDS = useMemo(
    () =>
      [
        {
          id: 'login',
          label: 'Login',
          modes: [LOGIN, REGISTRATION, UPDATE],
        },
        {
          id: 'email',
          label: 'E-mail',
          modes: [REGISTRATION, UPDATE],
        },
        {
          id: 'password',
          label: 'Password',
          modes: [LOGIN, REGISTRATION],
          type: 'password',
        },
        {
          id: 'confirmPassword',
          label: 'Confirm password',
          modes: [REGISTRATION],
          type: 'password',
        },
      ].filter(({ modes }) => modes.includes(mode)),
    [mode],
  );

  const handleChangeMode = () => {
    setCaptcha({ code: '', value: false });
    setMode(mode === LOGIN ? REGISTRATION : LOGIN);
  };

  const handleChange = name => e => {
    const { value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const { validation: _, ...rest } = state;

    const callback = validation => {
      if (validation) {
        setState({
          ...state,
          validation,
        });
      } else {
        closeAllModals();
      }
    };

    onSubmit({ ...rest, code: captcha.code }, mode, callback);
  };

  const handleCancel = () => {
    onCancel();
  };

  const getErrorMessage = name => get(state, `validation.${name}`, null);

  const fields = FIELDS.map(({ id, label, type }) => {
    return (
      <FormInput
        key={id}
        id={id}
        name={id}
        label={label}
        errorMessage={getErrorMessage(id)}
        value={state[id]}
        onChange={handleChange(id)}
        onEnterPress={handleSubmit}
        {...(type && { type })}
      />
    );
  });

  const formLegend = useMemo(() => {
    switch (mode) {
      case REGISTRATION:
        return 'Registration';

      case UPDATE:
        return 'Edit user profile';

      default:
        return 'Authorization';
    }
  }, [mode]);

  const showOkButton = mode !== REGISTRATION || captcha.isCompleted;

  return (
    <Form
      autoComplete="off"
      className={styles['auth-form']}
      name="auth"
      withCross
      onCrossClick={handleCancel}
    >
      <FormFieldset>
        <Legend>{formLegend}</Legend>
        {fields}
        {mode === UPDATE && (
          <FormButton
            className={styles['change-password-button']}
            label="Change password"
            onClick={changePassword}
          />
        )}
        {mode === REGISTRATION && (
          <Captcha
            value={captcha}
            fieldHeight={fieldHeight}
            fieldWidth={fieldWidth}
            checkboxHeight={checkboxHeight}
            checkboxWidth={checkboxWidth}
            onChange={setCaptcha}
          />
        )}
        {mode !== UPDATE && (
          <div>
            <span
              className={styles['auth-form__mode-switcher']}
              role="button"
              tabIndex={0}
              onClick={handleChangeMode}
            >
              {mode === LOGIN ? 'Registration' : 'Login'}
            </span>
          </div>
        )}
      </FormFieldset>
      <FormToolbar>
        <FormButton key="cancel" label="Cancel" onClick={handleCancel} />
        {showOkButton && (
          <FormButton key="ok" label="Ok" onClick={handleSubmit} />
        )}
      </FormToolbar>
    </Form>
  );
};

AuthForm.propTypes = {
  currentLogin: PropTypes.string,
  closeAllModals: PropTypes.func.isRequired,
  isUpdateMode: PropTypes.bool,
  getUserProfile: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

AuthForm.defaultProps = {
  currentLogin: '',
  isUpdateMode: false,
};

export default AuthForm;
