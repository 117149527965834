import { useEffect } from 'react';
import PropTypes from 'prop-types';

const AuthInfoService = ({ ping }) => {
  useEffect(() => {
    ping();
  }, [ping]);

  return null;
};

AuthInfoService.propTypes = {
  ping: PropTypes.func,
};

AuthInfoService.defaultProps = {
  ping: () => {},
};

export default AuthInfoService;
