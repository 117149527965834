import {
  AC_ADD_MODAL,
  AC_CLOSE_ALL_MODALS,
  AC_REMOVE_MODAL,
  AC_REMOVE_LAST_MODAL,
} from '../action-types';

export const acAddModal = (id, state = {}) => ({
  type: AC_ADD_MODAL,
  payload: { id, ...state },
});

export const acCloseAllModals = () => ({
  type: AC_CLOSE_ALL_MODALS,
});

export const acRemoveModal = id => ({
  type: AC_REMOVE_MODAL,
  payload: id,
});

export const acRemoveLastModal = () => ({
  type: AC_REMOVE_LAST_MODAL,
});
