import React from 'react';
import PropTypes from 'prop-types';

import styles from './Header.module.scss';
import { AccountMenuIcon, MainMenuIcon } from '../../../icons';
import { MODALS, MODALS_ID } from '../../../../constants';

const Header = ({ title, openModal }) => (
  <header className={styles['header']}>
    <button
      className={styles['header__toolbar-button']}
      type="button"
      onClick={() => {
        openModal(MODALS_ID.MAIN_MENU, {
          ...MODALS[MODALS_ID.MAIN_MENU],
        });
      }}
    >
      <MainMenuIcon />
    </button>
    <div className={styles['header__title']}>{title}</div>
    <button
      className={styles['header__toolbar-button']}
      type="button"
      onClick={() => {
        openModal(MODALS_ID.ACCOUNT_MODAL_MENU, {
          ...MODALS[MODALS_ID.ACCOUNT_MODAL_MENU],
        });
      }}
    >
      <AccountMenuIcon />
    </button>
  </header>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default Header;
