import { acRequestError, acRequestStart, acRequestSuccess } from './networking';
import { ENTITY_ID } from '../../../constants';
import { getNetworkError } from '../../../utils/network-requests';
import { acResetApp } from './common';
import { acLogout } from './auth';
import { getSessionToken } from '../../selectors';

export const acGetSpecialRoutes = callback => async (
  dispatch,
  getState,
  api,
) => {
  try {
    dispatch(acRequestStart(ENTITY_ID.API));

    const sessionToken = getSessionToken(getState());
    const specialRoutes = await api.special.getSpecialRoutes(sessionToken);

    callback(specialRoutes);

    dispatch(acRequestSuccess(ENTITY_ID.API));
  } catch (e) {
    const networkError = getNetworkError(e);
    dispatch(acRequestError(ENTITY_ID.API, networkError));

    if (networkError.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
  }
};
