import { parseResponse, get } from '../utils/network-requests';
import { URLS } from '../constants';

const getSpecialRoutes = async sessionToken => {
  const url = URLS.SPECIAL_ROUTES;

  const response = await get({
    url,
    headers: { 'session-token': sessionToken },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

export default {
  getSpecialRoutes,
};
