const {
  REACT_APP_FIFTEEN_PUZZLE_HOST = 'https://fifteen-puzzle.feature.by',
  REACT_APP_GAME_2048_HOST = 'https://game-2048.feature.by',
  REACT_APP_SPECIAL_ROUTES = 'api/special-routes',
} = process.env;

const URLS = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  LOGOUT: '/auth/logout',
  USER_UPDATE: '/auth/update',
  USER_PROFILE: '/auth/profile',
  CHANGE_PASSWORD: '/auth/change-password',
  CAPTCHA: '/api/captcha',
  PING: '/api/ping',
  FIFTEEN_PUZZLE_HOST: REACT_APP_FIFTEEN_PUZZLE_HOST,
  GAME_2048_HOST: REACT_APP_GAME_2048_HOST,
  SPECIAL_ROUTES: REACT_APP_SPECIAL_ROUTES,
};

export default URLS;
