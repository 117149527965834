import { createSelector } from 'reselect';
import { get } from 'lodash';

import { ENTITY_ID, MODELS } from '../../constants';

const simpleSelector = model => state => get(state, model);

const loadStatusSelectorsList = Object.values(ENTITY_ID).map(prefix =>
  simpleSelector(`${MODELS.NETWORKING}.${prefix}LoadStatus`),
);

export const getIsFetching = createSelector(
  [...loadStatusSelectorsList],
  (...loadStatuses) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const status of loadStatuses) {
      if (status === 1) {
        return true;
      }
    }

    return false;
  },
);
