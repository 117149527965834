import { connect } from 'react-redux';

import Content from './Content';

const mapStateToProps = state => ({
  temp: state.temp,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
