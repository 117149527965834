const MODELS = {
  AUTHORIZATION_IS_CHECKED: 'auth.authorizationIsChecked',
  LOGIN: 'auth.login',
  LOCATION_PATHNAME: 'router.location.pathname',
  LOCATION_SEARCH: 'router.location.search',
  MESSAGES_ITEMS: 'messages.items',
  MODALS_LIST: 'modals.modalsList',
  NETWORKING: 'networking',
  SESSION_TOKEN: 'auth.sessionToken',
  TOUCH_SCREEN_DETECTED: 'media.touchScreenDetected',
};

export default MODELS;
