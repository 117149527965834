export const AC_SET_MEDIA = 'AC_SET_MEDIA';

export const AC_SET_LOCATION = 'AC_SET_LOCATION';

export const AC_ADD_MODAL = 'AC_ADD_MODAL';
export const AC_REMOVE_MODAL = 'AC_REMOVE_MODAL';
export const AC_REMOVE_LAST_MODAL = 'AC_REMOVE_LAST_MODAL';
export const AC_CLOSE_ALL_MODALS = 'AC_CLOSE_ALL_MODALS';

export const AC_LOGOUT = 'AC_LOGOUT';
export const AC_SET_AUTH_DATA = 'AC_SET_AUTH_DATA';
export const AC_SET_AUTHORIZATION_IS_CHECKED =
  'AC_SET_AUTHORIZATION_IS_CHECKED';

export const AC_REQUEST_START = 'AC_REQUEST_START';
export const AC_REQUEST_ERROR = 'AC_REQUEST_ERROR';
export const AC_REQUEST_SUCCESS = 'AC_REQUEST_SUCCESS';
export const AC_SET_ENTITY_DATA = 'AC_SET_ENTITY_DATA';

export const AC_SET_TOUCH_SCREEN_DETECTED = 'AC_SET_TOUCH_SCREEN_DETECTED';

export const AC_ADD_MESSAGE = 'AC_ADD_MESSAGE';
export const AC_REMOVE_MESSAGE = 'AC_REMOVE_MESSAGE';

export const AC_RESET_APP = 'AC_RESET_APP';
