import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import delay from '../../../utils/delay';
import { MESSAGES_CONFIG } from '../../../constants';

import styles from './SnackItem.module.scss';

const { DURATION_MS } = MESSAGES_CONFIG;

const SnackItem = ({ id, message, type, date, status, onClose }) => {
  const li = useRef(null);
  const timer = useRef(0);

  const removeSnack = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    li.current.classList.add(styles['snack-item--hide-on']);
    delay(300).then(() => {
      onClose(id);
    });
  }, []);

  useEffect(() => {
    if (DURATION_MS > 0) {
      timer.current = setTimeout(() => {
        removeSnack();
      }, DURATION_MS);
    }
  }, []);

  const className = classNames(
    styles['snack-item'],
    type && styles[`snack-item--${type.toLowerCase()}`],
  );

  return (
    <li
      key={`${message}-${status}-${date}`}
      className={className}
      ref={li}
      onClick={removeSnack}
    >
      <span className={styles['snack-item__message']}>{message}</span>
      <span className={styles['snack-item__secondary-info']}>
        {date && <span> date: {date}</span>}
        {status && <span> status: {status}</span>}
      </span>
    </li>
  );
};

SnackItem.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  date: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func,
};

SnackItem.defaultProps = {
  date: '',
  status: '',
  onClose: () => {},
};

export default SnackItem;
