import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Form from '../../../../Form';
import FormFieldset from '../../../../Form/shared/FormFieldset';
import Legend from '../../../../Form/shared/Legend';
import Menu from '../../../../Menu';
import { usePageId } from '../../../../../hooks';
import OPTIONS from './options';

import styles from './MainMenuForm.module.scss';

const MainMenuForm = ({
  isLogged,
  closeAllModals,
  closeModal,
  getSpecialRoutes,
  openModal,
}) => {
  const history = useHistory();
  const pageId = usePageId(history.location);
  const [parentId, setParentId] = useState();
  const [specialItems, setSpecialItems] = useState([]);

  useEffect(() => {
    if (isLogged) {
      getSpecialRoutes(setSpecialItems);
    }
  }, [isLogged]);

  const handleLinkClick = linkUrl => e => {
    e.preventDefault();
    history.push(linkUrl);
    closeAllModals();
  };

  const goTo = optionId => () => setParentId(optionId);

  const options = useMemo(
    () =>
      OPTIONS[pageId]({
        isLogged,
        parentId,
        closeAllModals,
        handleLinkClick,
        goTo,
        openModal,
        SPECIAL_ITEMS: specialItems,
      }),
    [pageId, parentId, specialItems],
  );

  return (
    <Form
      className={styles['main-menu-form']}
      name="mainMenu"
      withCross
      onCrossClick={closeModal}
    >
      <FormFieldset>
        <Legend>Main menu</Legend>
        <Menu options={options} />
      </FormFieldset>
    </Form>
  );
};

MainMenuForm.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  closeAllModals: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getSpecialRoutes: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default MainMenuForm;
