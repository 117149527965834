import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './shared/MenuItemButton';
import Link from './shared/MenuItemLink';
import List from '../List';
import ListItem from '../List/shared/ListItem';
import { isFilledArray } from '../../utils/common-utils';

import styles from './Menu.module.scss';

const Menu = ({ className, options, ...rest }) => {
  const ownClassName = classNames(styles['menu'], className && className);

  const items =
    isFilledArray(options) &&
    options
      .map(({ id, isVisible, label, url, onClick, ...restItemProps }) => {
        const actionProps = { href: url, onClick };
        const Action = url ? Link : Button;

        return (
          isVisible !== false && (
            <ListItem
              key={id}
              className={styles['menu-item']}
              {...restItemProps}
            >
              <Action {...actionProps}>{label}</Action>
            </ListItem>
          )
        );
      })
      .filter(Boolean);

  return (
    <div className={ownClassName}>
      <List {...rest}>{items}</List>
    </div>
  );
};

Menu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

Menu.defaultProps = {
  options: [],
  className: '',
};

export default Menu;
