import { connect } from 'react-redux';

import Header from './Header';
import { acAddModal } from '../../../../store/actions/creators';

const mapDispatchToProps = {
  openModal: acAddModal,
};

export default connect(null, mapDispatchToProps)(Header);
