import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './FormButton.module.scss';

const FormButton = ({
  className,
  label,
  type: buttonType,
  onClick,
  ...rest
}) => {
  const ownClassName = classNames(styles['form-button'], className);

  return (
    <button
      className={ownClassName}
      type={buttonType}
      onClick={onClick}
      {...rest}
    >
      {label}
    </button>
  );
};

FormButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
};

FormButton.defaultProps = {
  className: '',
  label: '',
  type: 'button',
  onClick: () => {},
};

export default FormButton;
